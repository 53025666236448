//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAnfDXN4cD3h425Wh0s-7e09tk6jCh0JMk",
  authDomain: "ethiocoin-556df.firebaseapp.com",
  databaseURL: "https://ethiocoin-556df-default-rtdb.firebaseio.com",
  projectId: "ethiocoin-556df",
  storageBucket: "ethiocoin-556df.firebasestorage.app",
  messagingSenderId: "1055340113610",
  appId: "1:1055340113610:web:266334c798ab108a6a0e33",
  measurementId: "G-Y7WXCMRGY6"
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
