import React from 'react'
import AdminAirdrop from '../Components/AdminAirdrop'


const AirdropWallets = () => {
  return (
    <AdminAirdrop/>
  )
}

export default AirdropWallets